<template>
    <b-container>
        <div style="margin:5px;">
            <b-row>
                <div class="card" style="margin:5px 20px;width:100%">
                    <div class="card-body">
                        <b-form>
                            <b-form-group id="groupOptions" label="排序类型:" label-for="options">
                                <b-form-select id="options"
                                    v-model="selectedOption" 
                                    :options="options">
                                </b-form-select>
                            </b-form-group>
                            <div style="text-align:right;margin-right:20px;">
                                <span style="margin-right:10px;margin-bottom:5px;font-size:12px;line-height:14px;color:#6c757d" class="d-none d-sm-block">
                                    提示：拖拽图片卡片进行排序
                                </span>
                                <b-button type="button" @click="search" style="margin-right:5px;">
                                    刷新
                                </b-button>
                                <b-button type="button" @click="save" variant="primary" style="margin-left:5px;">
                                    保存排序
                                </b-button>
                                <b-button type="button" @click="add" variant="warning" style="margin-left:5px;">
                                    添加
                                </b-button>
                                <b-button type="button" @click="remove" variant="danger" style="margin-left:5px;">
                                    移除
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </b-row>
            <b-check v-model="checkedAllProducts" @change="togglleCheckedAll"
                style="margin-left:10px;">
                选择/不选择所有产品
            </b-check>
            <div v-if="products.length > 0" 
                class="products"
                style="display: flex;flex-wrap:wrap;justify-content:center;width:100%;padding:10px;">
                <div v-for="product in products" :key="product.id"
                    style="cursor:pointer;margin:10px 5px;width:20%;position:relative;">
                    <b-check v-model="product.checked" @change="changeProductChecked"
                        style="position:absolute;left:5px;top:5px;">
                    </b-check>
                    <b-img :src="getPictureUrl(product.picture_url)" thumbnail style="width:98%;height:auto;"/>
                    <div border style="width:100%;display:block;overflow:hidden;margin-top:5px;text-align:center;">
                        {{ product.product_code }}
                    </div>
                </div>
            </div>
            <div v-else style="margin:10px;">
                <h6>没有产品</h6>
            </div>
            <div style="margin-top:10px;margin-bottom:10px;width:100%;text-align:center;">
                <span>总数: {{total}} 个</span>
            </div>
        </div>
        <b-modal id="product-sort-add" title="添加排序信息" size="lg"
            hide-footer
            v-model="showAddDialog">
            <b-check v-model="checkedAllUnsetteddProducts" @change="togglleUnsettedCheckedAll"
                style="margin-left:10px;">
                选择/不选择所有产品
            </b-check>
            <div v-if="unsetProducts.length > 0" 
                class="products"
                style="display: flex;flex-wrap:wrap;justify-content:center;width:100%;padding:10px;">
                <div v-for="product in unsetProducts" :key="'sort_' + product.id"
                    style="cursor:pointer;margin:10px 5px;width:20%;position:relative;">
                    <b-check v-model="product.checked" @change="changeUnsettedProductChecked"
                        style="position:absolute;left:5px;top:5px;">
                    </b-check>
                    <b-img :src="getPictureUrl(product.picture_url)" thumbnail style="width:98%;height:auto;"/>
                    <div border style="width:100%;display:block;overflow:hidden;margin-top:5px;text-align:center;">
                        {{ product.product_code }}
                    </div>
                </div>
                <div style="margin-top:10px;width:90%;">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalUnsetProducts"
                        :per-page="pageSize"
                        align="center">
                    </b-pagination>
                    <div style="height:30px;line-height:30px;text-align:center;">
                        第 {{ currentPage }} 页
                        总计: {{ totalUnsetProducts }} 行
                    </div>
                </div>
                <div class="d-flex bg-senconday text-light align-items-center px-3 py-2">
                    <b-button @click="saveNewSort"
                        variant="primary" size="md"
                        style="margin-left:10px;margin-top:5px;">
                        保存
                    </b-button>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import { 
    Indicator, 
    MessageBox
} from 'mint-ui';

import Sortable from 'sortablejs';

import { 
    getHomeProductList,
    getRecommendProductList,
    getUnSettedProductList,
    updateProducts
} from "api/product";

import { 
    getPicturePath 
} from "api/picture"

export default {
    data() {
        return {
            loading: true,
            products: [],
            total: 0,
            selectedOption: 'home',
            options: [
                { value: 'home', text: '首页' },
                { value: 'recommend', text: '推荐' }
            ],
            checkedAllProducts: false,
            showAddDialog: false,
            checkedAllUnsetteddProducts: false,
            unsetProducts: [],
            totalUnsetProducts: 0,
            pageSize: 20,
            currentPage: 1
        }
    },
    mounted() { 
        this.search();
    },
    watch: {
        selectedOption: function(newValue, oldValue) {
            this.search();
        },
        currentPage: function(newValue, oldValue) {
            this.searchUnSetProducts();
        }
    },
    methods: {
        getPictureUrl(pictureUrl) {
            return pictureUrl + "?random=" + Math.floor((Math.random() * 100) + 1);
        },
        search() {
            this.checkedAllProducts = false;
            Indicator.open({ text: '加载中...', spinnerType: 'fading-circle' });
            let successCallback = response => {
                if(response.status == 200) {
                    this.products = response.data.data;
                    if(this.products.length > 0) {
                        this.products.forEach(product => {
                            if(product.picture_url) {
                                product.picture_url = getPicturePath(product.picture_url);
                                this.$set(product, "checked", false);
                            }
                        });
                    
                        this.total = response.data.total;

                        this.$nextTick(() => {
                            const el = document.querySelectorAll('.products')[0];
                            let self = this;
                            Sortable.create(el, {
                                onEnd({ newIndex, oldIndex }) {
                                    const targetRow = self.products.splice(oldIndex, 1)[0];
                                    self.products.splice(newIndex, 0, targetRow);
                                }
                            });
                        });
                    } else {
                        this.products = [];
                        this.total = 0;
                    }
                }
                Indicator.close();
            };
            if(this.selectedOption == 'home') {
                getHomeProductList(successCallback);
            } else if (this.selectedOption == 'recommend') {
                getRecommendProductList(successCallback);
            }
        },
        changeProductChecked() {
            this.checkedAllProducts = this.products.filter(product => {
                return product.checked == true;
            }).length == this.products.length;
        },
        togglleCheckedAll() {
            this.products.forEach(product => {
                product.checked = this.checkedAllProducts;
            });
        },
        changeUnsettedProductChecked() {
            this.checkedAllUnsetteddProducts = this.unsetProducts.filter(product => {
                return product.checked == true;
            }).length == this.unsetProducts.length;
        },
        togglleUnsettedCheckedAll() {
            this.unsetProducts.forEach(product => {
                product.checked = this.checkedAllUnsetteddProducts;
            });
        },
        save() {
            if(this.products.length == 0) {
                MessageBox('保存失败', '查询结果为空');
                return;
            }
            let saveItems = [];
            this.products.forEach((item, index) => {
                saveItems.push(item.product_code);
            });
            let saveObject = { 'method': 'set_home', 'products': saveItems };
            if (this.selectedOption == 'recommend') {
                saveObject.method = 'set_recommend';
            }
            updateProducts(saveObject, response => {
                if (response.status == 200) {
                    MessageBox('保存成功', '保存成功');
                    this.search();
                } else {
                    MessageBox('保存失败', '保存失败');
                }
            });
        },
        searchUnSetProducts() {
            let params = {
                "is_set_new": true,
                "is_home": false,
                "is_recommend": false,
                "page_size": this.pageSize,
                "current_page": this.currentPage
            };
            if(this.selectedOption == 'home') {
                params.is_home = true;
            } else if(this.selectedOption == 'recommend') {
                params.is_recommend = true;
            }
            getUnSettedProductList(params, response => {
                if(response.status == 200) {
                    this.unsetProducts = response.data.data;
                    if(this.unsetProducts.length > 0) {
                        this.unsetProducts.forEach(product => {
                            if(product.picture_url) {
                                product.picture_url = getPicturePath(product.picture_url);
                                this.$set(product, "checked", false);
                            }
                        });
                        this.totalUnsetProducts = response.data.total;
                    } else {
                        this.unsetProducts = [];
                        this.totalUnsetProducts = 0;
                    }
                }
            });
        },
        add() {
            this.showAddDialog = true;
            this.checkedAllUnsetteddProducts = false;
            this.currentPage = 1;
            this.searchUnSetProducts();
        },
        saveNewSort() {
            if(this.unsetProducts.length == 0) {
                MessageBox('保存失败', '查询结果为空');
                return;
            }
            let saveItems = [];
            this.unsetProducts.forEach((item, index) => {
                if(item.checked == true) {
                    saveItems.push(item.product_code);
                }
            });
            if(saveItems.length == 0) {
                MessageBox('保存失败', '请选择产品');
                return;
            }
            MessageBox.confirm('确定执行此操作?').then(action => {
                let saveObject = { 'method': 'set_new_home', 'products': saveItems };
                if (this.selectedOption == 'recommend') {
                    saveObject.method = 'set_new_recommend';
                }
                updateProducts(saveObject, response => {
                    if (response.status == 200) {
                        MessageBox('保存成功', '保存成功');
                        this.showAddDialog = false;
                        this.search();
                    } else {
                        MessageBox('保存失败', '保存失败');
                    }
                });
            }).catch(() => {});
        },
        remove() {
            if(this.products.length == 0) {
                MessageBox('保存失败', '查询结果为空');
                return;
            }
            let saveItems = [];
            this.products.forEach((item, index) => {
                if(item.checked == true) {
                    saveItems.push(item.product_code);
                }
            });
            if(saveItems.length == 0) {
                MessageBox('保存失败', '请选择产品');
                return;
            }
            MessageBox.confirm('确定执行此操作?').then(action => {
                let saveObject = { 'method': 'unset_home', 'products': saveItems };
                if (this.selectedOption == 'recommend') {
                    saveObject.method = 'unset_recommend';
                }
                updateProducts(saveObject, response => {
                    if (response.status == 200) {
                        MessageBox('保存成功', '保存成功');
                        this.search();
                    } else {
                        MessageBox('保存失败', '保存失败');
                    }
                });
            }).catch(()=>{});
        }
    }
}
</script>